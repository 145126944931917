const conditions = [{
  title: "최종 제출 여부",
  name: "finished",
  items: [{
    title: "예",
    value: true,
  }, {
    title: "아니오",
    value: false,
  }]
}, {
  title: "취소 여부",
  name: "canceled",
  items: [{
    title: "예",
    value: true,
  }, {
    title: "아니오",
    value: false,
  }]
}, {
  title: "진행 현황",
  name: "status",
  items: [{
    title: "접수 완료",
    value: "unproven",
  }, {
    title: "심사 중",
    value: "judge",
  }, {
    title: "보완",
    value: "denied",
  }, {
    title: "통과",
    value: "pass",
  }, {
    title: "반려",
    value: "fail",
  }, {
    title: "보류",
    value: "pending",
  }, {
    title: "처리 완료",
    value: "done",
  }]
},];

export default {conditions};